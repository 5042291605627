<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('版本名称')}}<template slot="title">{{$t('版本名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('版本名称')" />
      </a-form-model-item>


      <a-form-model-item prop="version" >
        <span slot="label" >
          <a-tooltip>
            {{$t('版本号')}}<template slot="title">{{$t('版本号')}}</template>
          </a-tooltip>
        </span>
        <a-input-number @input="onInput"   v-model="form.version" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('版本号')" />
      </a-form-model-item>



      <a-form-model-item prop="content" >
        <span slot="label" >
          <a-tooltip>
            {{$t('本次产品更新内容')}}<template slot="title">{{$t('本次产品更新内容')}}</template>
          </a-tooltip>
        </span>
        <a-textarea v-model="form.content" />
      </a-form-model-item>





        <a-form-model-item prop="status" >
        <span slot="label" >
          <a-tooltip>
            {{$t('是否强制更新')}}<template slot="title">{{$t('是否强制更新')}}</template>
          </a-tooltip>
        </span>
            <a-radio-group v-model="form.musted" button-style="solid">
                <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
            </a-radio-group>
        </a-form-model-item>



      <a-form-model-item prop="url" >
        <span slot="label" >
          <a-tooltip>
            {{$t('更新地址')}}<template slot="title">{{$t('更新地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.url" :placeholder="$t('通用.输入.请输入')" allow-clear />
      </a-form-model-item>



        <a-form-model-item :label="this.$t('APP类型')" prop="type">
            <a-select style="width: 100%" v-model="form.type" placeholder="请选择APP类型">
                <a-select-option  v-for="(d, index) in typeOptions" :key="index" :value="d.value">{{ d.label }}
                </a-select-option>
            </a-select>
        </a-form-model-item>




      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAppVersion, addAppVersion, updateAppVersion } from '@/api/appVersion/appVersion'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    Editor
  },
  data () {
    return {
        statusOptions:[
            {label:"是",value:1},
            {label:"否",value:0}
        ],
        typeOptions:[
            {label:"安卓",value:1},
        ],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        name: null,

        content: null,

        musted: 1,

        url: null,

        type: 1,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
          name: [{ required: true, message: '请输入版本名称', trigger: 'blur' }],
          version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
          content: [{ required: true, message: '请输入更新内容', trigger: 'blur' }],
          url: [{ required: true, message: '请输入更新地址', trigger: 'blur' }],
          type: [{ required: true, message: '请输入更新app类型', trigger: 'blur' }],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
      onInput(event) {
          // 使用 replace 方法移除非数字字符
          this.form.version = event.target.value.replace(/\D/g, '');
      },
      onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        content: null,
        musted: 1,
        url: null,
        type: 1,
        createTime: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAppVersion({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateAppVersion(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addAppVersion(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
