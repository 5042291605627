import request from '@/utils/request'


// 查询app版本更新记录列表
export function listAppVersion(query) {
  return request({
    url: '/appVersion/appVersion/list',
    method: 'get',
    params: query
  })
}

// 查询app版本更新记录分页
export function pageAppVersion(query) {
  return request({
    url: '/appVersion/appVersion/page',
    method: 'get',
    params: query
  })
}

// 查询app版本更新记录详细
export function getAppVersion(data) {
  return request({
    url: '/appVersion/appVersion/detail',
    method: 'get',
    params: data
  })
}

// 新增app版本更新记录
export function addAppVersion(data) {
  return request({
    url: '/appVersion/appVersion/add',
    method: 'post',
    data: data
  })
}

// 修改app版本更新记录
export function updateAppVersion(data) {
  return request({
    url: '/appVersion/appVersion/edit',
    method: 'post',
    data: data
  })
}

// 删除app版本更新记录
export function delAppVersion(data) {
  return request({
    url: '/appVersion/appVersion/delete',
    method: 'post',
    data: data
  })
}
